/** @jsx jsx */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {useState} from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import {css, jsx} from '@emotion/react';
import {Trans, useI18next, useTranslation} from 'gatsby-plugin-react-i18next';

import ArrowSVG from '../../images/clickables/cta_arrow.svg';
import GrowableArrowSVG from '../../images/clickables/cta_arrow_growable.svg';
import {breakpoints, colors, ContentContainer, fonts, fontWeights} from '../../styles/theme';
import {CTALink, CTALinkDark, Link} from './Typography';
import X from '../../images/clickables/x.png';
import sendGoogleAnalyticsEvent from '../../helpers/utils/google-analytics';


/*
 * Constants
 */
const overlayAnimationTime = 400;  // milliseconds


/*
 * Private Elements
 */

// CTA Arrow
const GrowableArrowWrapper = styled.span`
    cursor: pointer;
    
    // NOTE: the "arrow head" is designed to work on 20px base width
    svg {
        width: 20px;
        height: 20px;
        transition: width 0.3s ease-in-out;
    }

    &:hover,
    &.hover {
        svg {
            width: 44px;
        }
    }
`;


// Embedded Element
const StyledEmbeddedElement = styled.div`
    &.embedded-video {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &.embedded-podcast {
        iframe {
            width: 100%;
            height: 100%;
        }
    }
`;


// Video Overlay
const Overlay = styled.div`
    opacity: 0;
    transition: opacity ${overlayAnimationTime}ms ease-in;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .7);
    z-index: 100;

    &.active {
        opacity: 1;
    }

    &.hidden {
        visibility: hidden;
        z-index: -999999;
    }
`;

const StyledVideoOverlay = styled(Overlay)`
    .video-overlay-wrapper {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 982px;
        padding: 0 20px;

        .close {
            background: ${colors.transparent} url(${X}) no-repeat scroll center center;
            position: absolute;
            top: -50px;
            right: 0;
            cursor: pointer;
            z-index: 9999;
            height: 40px;
            width: 40px;
            background-size: 24px;
        }
    }

    @media (max-width: ${breakpoints.md}) and (orientation: landscape) {
        .video-overlay-wrapper {
            .video-wrapper {
                .close {
                    display: none;
                }
            }
        }
    }
`;


// Clickable element
const Clickable = styled.button`
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    background-color: ${colors.transparent};
    align-items: center;
    display: flex;

    &:focus,
    &:active {
        border: 0;
        outline: 0;
    }
`;

// Base Button
const BaseButtonStyles = css`
    height: fit-content;
    min-height: 48px;
    border-radius: 48px;
    padding: 15px 24px;
    border: 0;

    font-size: 14px;
    line-height: 18px;
    font-family: ${fonts.sansSerif};
    font-weight: ${fontWeights.semiBold};
    letter-spacing: 0.5px;
    text-align: center;
    display: inline-block;

    &:focus {
        outline: 0;
    }
`;

// Primary Button Styles
const PrimaryButtonStyles = css`
    position: relative;
    color: ${colors.white};
    background-color: ${colors.darkPurple};
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        min-height: 48px;
        border-radius: 48px;
        padding: 15px 24px;
        background-image: linear-gradient(to right, #8286ED, ${colors.darkPurple});
        opacity: 0;
        transition: opacity 0.3s linear;
        z-index: -1;
    }

    &:hover,
    &:focus {
        color: ${colors.white};
        text-decoration: none;

        &::before {
            opacity: 1;
        }
    }
`;

// Secondary Button Styles
const SecondaryButtonStyles = css`
    position: relative;
    color: ${colors.white};
    background-color: ${colors.transparent};
    border: 1px solid ${colors.white};
    transition: all 0.2s linear;

    &:hover,
    &:focus {
        color: ${colors.darkPurple};
        background-color: ${colors.white};
        border: 1px solid ${colors.darkPurple};
        text-decoration: none;

        &::before {
            // Cancel the animation of the primary button
            opacity: 0;
        }
    }
`;

// Arrow Circle Button
const StyledArrowCircleButton = styled.button`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${colors.white};
    box-shadow: 0 2px 7px 0 rgb(0, 0, 0, 0.3);
    display: flex;
    align-content: center;
    border: 0;
    padding: 0;
    transition: background-color 0.2s linear;

    &:active {
        outline: 0;
    }

    &:hover {
        &:not(.arrow-disabled) {
            background-color: ${colors.darkPurple};

            svg {
                path {
                    stroke: ${colors.white};
                }
            }
        }
    }

    &.arrow-prev {
        svg {
            transform: scale(-1, 1);
        }
    }

    &.arrow-disabled {
        opacity: 0.5;
    }

    svg {
        margin: auto;
        transition: stroke 0.2s linear;

        path {
            stroke: ${colors.darkPurple};
        }
    }
`;

// CSS for link element that wraps the CTA element
const ctaWrapperCss = css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 100%;

    .cta-content {
        padding-right: 15px;
    }

    .cta-arrow {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: 45px;
    }
  
    &.inverted {
        flex-direction: row-reverse;
        justify-content: flex-end;

        .cta-content {
            padding-right: 0;
            padding-left: 10px;
        }
    }
`;

/*
 * Public Elements
 */

/**
 * Generate a CTA
 *
 * @param {string} to - the url of the link
 * @param {string} target - the target of the link
 * @param {object} state - location state to pass with link
 * @param {boolean} colored - whether the CTA is colored (purple) or white
 * @param {node} children - content of the CTA
 * @param {boolean} invertedDirection - whether the CTA is point right (default) or left
 */
const CTA = ({to, target = '_self', state = null, colored = false, invertedDirection = false, children}) => {
    const [isHovering, setIsHovering] = useState(false);

    // Get the correct arrow element depending on the colored setting
    const LinkElement = colored ? CTALink : CTALinkDark;

    // Define the classes to use depending on whether the element is being hovered or not
    const classNames = isHovering ? 'hover' : '';

    const classInvertedDirection = invertedDirection ? 'inverted' : '';

    // CTA arrow with the generated ID. No onClick action, since the outer element is a simple link
    const arrow = (
        <CTAArrow classNames={classNames} onClick={() => {}} colored={colored} invertedDirection={invertedDirection} />
    );

    // Activate/deactivate the CTA Arrow when hovering the outer element
    const onMouseEnter = () => setIsHovering(true);
    const onMouseLeave = () => setIsHovering(false);

    // Return
    return (
        <LinkElement
            to={to}
            target={target}
            state={state}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={classInvertedDirection}
            css={ctaWrapperCss}
        >
            <span className="cta-content">{children}</span>
            <span className="cta-arrow">{arrow}</span>
        </LinkElement>
    );
};

CTA.propTypes = {
    to: PropTypes.string,
    target: PropTypes.string,
    state: PropTypes.object,
    colored: PropTypes.bool,
    children: PropTypes.node.isRequired,
    invertedDirection: PropTypes.bool,
};

CTA.defaultProps = {
    to: undefined,
    target: '_self',
    state: null,
    colored: false,
    invertedDirection: false,
};

/**
 * Generate a clickable arrow used in CTA's
 *
 * @param {function} onClick - function to be executed when the element is clicked
 * @param {string} classNames - additional classes to add to the clickable element
 * @param {boolean} colored - whether the CTA is colored (purple) or white
 * @param {boolean} invertedDirection - whether the Arrow is point right (default) or left
 */
const CTAArrow = ({onClick = () => {}, classNames = '', colored = false, invertedDirection = false}) => {
    /* eslint-disable i18next/no-literal-string */
    const svgCss = css`
        stroke: ${colored ? colors.darkPurple : colors.white};
        // The SVG image is pointing left, we mainly want to point right. Right is considered the normal direction.
        // We apply a rotation to get the normal direction; when inverted direction, just don't apply it.
        transform: ${invertedDirection ? 'scale(1)' : 'scale(-1)'};
    `;
    /* eslint-enable i18next/no-literal-string */

    return (
        <GrowableArrowWrapper className={`cta-arrow ${classNames}`} onClick={onClick}>
            <GrowableArrowSVG css={svgCss} />
        </GrowableArrowWrapper>
    );
};

CTAArrow.propTypes = {
    onClick: PropTypes.func,
    classNames: PropTypes.string,
    colored: PropTypes.bool,
    invertedDirection: PropTypes.bool,
};

CTAArrow.defaultProps = {
    onClick: () => {},
    classNames: '',
    colored: false,
    invertedDirection: false,
};


// Primary Button
const PrimaryButton = styled.button`
    ${BaseButtonStyles};
    ${PrimaryButtonStyles};
`;


// Secondary Button
const SecondaryButton = styled(PrimaryButton)`
    ${SecondaryButtonStyles};
`;


// Link styled as Primary Button
const LinkAsPrimaryButton = styled(Link)`
    ${BaseButtonStyles};
    ${PrimaryButtonStyles};
`;


// Link styled as Secondary Button
const LinkAsSecondaryButton = styled(LinkAsPrimaryButton)`
    ${SecondaryButtonStyles};
`;


// Contact Sales Button
const ContactSalesButton = () => {
    const {navigate} = useI18next();

    return (
        <PrimaryButton onClick={() => navigate('/about/contact-sales')}><Trans>Contact Sales</Trans></PrimaryButton>
    );
};


// Embedded Video
const EmbeddedVideo = ({iframeSource = null}) => (
    <StyledEmbeddedElement className="embedded-element embedded-video">
        <iframe
            title={iframeSource}  // Use the video URL as the iframe title (it should be unique)
            src={iframeSource}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
        />
    </StyledEmbeddedElement>
);

EmbeddedVideo.propTypes = {
    iframeSource: PropTypes.string,
};

EmbeddedVideo.defaultProps = {
    iframeSource: null,
};


// Embedded Podcast
const EmbeddedPodcast = ({iframeSource = null}) => (
    <StyledEmbeddedElement className="embedded-element embedded-podcast">
        <iframe
            src={iframeSource}
            title={iframeSource}
            frameBorder="0"
            scrolling="no"
        />
    </StyledEmbeddedElement>
);

EmbeddedPodcast.propTypes = {
    iframeSource: PropTypes.string,
};

EmbeddedPodcast.defaultProps = {
    iframeSource: null,
};


// Video Overlay
const WatchVideoOverlay = ({videoUrl, opened, setOpened}) => {
    const [hidden, setHidden] = useState(true);
    const [iframeSource, setIframeSource] = useState(videoUrl);

    const closeVideo = () => {
        // Set "opened" as false, which will trigger the fade out animation
        setOpened(false);

        setTimeout(() => {
            // After the animation is over, actually hide the element, and set the iframe source to null (which will
            // remove the video from the DOM, essentially stopping it, otherwise it would keep playing in the
            // background).
            setHidden(true);
            setIframeSource(null);

            // After removing the video, we want to load it again, so that it is ready for next time it is opened.
            setTimeout(() => setIframeSource(videoUrl), 100);
        }, overlayAnimationTime);
    };

    const overlayClasses = [];

    // If the video should be opened, add the correct opened class to trigger the fade in animation
    if (opened) {
        /* eslint-disable-next-line i18next/no-literal-string */
        overlayClasses.push('active');

        // If it is still hidden, remove the hidden class which will make the video visible (and thus allowing the fade
        // in animation to be seen).
        if (hidden) {
            setHidden(false);
        }
    } else {
        // In case the video is not opened AND is hidden, add the correct hidden class to it.
        // NOTE: don't forget the video may be closed but NOT hidden. This happens when we close the video and are still
        // waiting for the fade out animation to finish, after which we actually hide the component.
        if (hidden) {  // eslint-disable-line no-lonely-if
            /* eslint-disable-next-line i18next/no-literal-string */
            overlayClasses.push('hidden');
        }
    }

    return (
        <StyledVideoOverlay className={overlayClasses.join(' ')} onClick={closeVideo}>
            <div className="video-overlay-wrapper">
                <div className="close" />
                <EmbeddedVideo iframeSource={iframeSource} />
            </div>
        </StyledVideoOverlay>
    );
};

WatchVideoOverlay.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    opened: PropTypes.bool.isRequired,
    setOpened: PropTypes.func.isRequired,
};


/**
 * Button to display a video on top of an overlay
 *
 * @param {string} videoUrl - URL of the embedded video to show (Vimeo, Youtube, ...)
 * @param {string} gaLabel - Video Label to send to Google Analytics
 */
const WatchVideoButton = ({videoUrl, gaLabel}) => {
    const [opened, setOpened] = useState(false);

    const WatchVideoClick = () => {
        setOpened(true);

        // Send custom event to Google Analytics if we have gaLabel
        if (gaLabel) {
            /* eslint-disable-next-line i18next/no-literal-string */
            sendGoogleAnalyticsEvent('videos', 'play', gaLabel);
        }
    };

    return (
        <>
            <SecondaryButton onClick={WatchVideoClick}><Trans>Watch Video</Trans></SecondaryButton>
            <WatchVideoOverlay videoUrl={videoUrl} opened={opened} setOpened={setOpened} />
        </>
    );
};

WatchVideoButton.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    gaLabel: PropTypes.string,
};

WatchVideoButton.defaultProps = {
    gaLabel: undefined,
};


// End of Page CTA
const StyledEndOfPageCTA = styled.div`
    ${ContentContainer} {
        padding-left: 20px;
        padding-right: 20px;

        .cta {
            width: 100%;
            max-width: 1100px;
            margin-left: auto;
            margin-right: auto;
            padding: 50px 8% 35px;
            margin-bottom: -55px;
            position: relative;

            background: linear-gradient(180deg, #EEEEFF 0%, #FFFFFF 100%);

            .cta-btn {
                display: flex;
                align-items: center;

                ${LinkAsPrimaryButton} {
                    margin-left: 0;
                    margin-top: 40px;
                }
            }
        }
    }

    .cta-bottom-background {
        height: 35px;
        background-color: ${colors.black};
    }


    @media (min-width: ${breakpoints.md}) {
        ${ContentContainer} {
            padding-left: 10%;
            padding-right: 10%;

            .cta {
                margin-bottom: -35px;

                .cta-btn {
                    ${LinkAsPrimaryButton} {
                        margin-left: auto;
                        margin-top: 0;
                    }
                }
            }
        }
    }
`;

const EndOfPageCTA = ({link, linkText, cssStyles = null, children}) => (
    <StyledEndOfPageCTA css={cssStyles}>
        <ContentContainer>
            <div className="cta">
                <div className="row no-gutters">
                    <div className="col-12 col-md-8">
                        {children}
                    </div>

                    <div className="col-12 col-md-4 cta-btn">
                        <LinkAsPrimaryButton to={link}>{linkText}</LinkAsPrimaryButton>
                    </div>
                </div>
            </div>
        </ContentContainer>

        <div className="cta-bottom-background" />
    </StyledEndOfPageCTA>
);

EndOfPageCTA.propTypes = {
    link: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    cssStyles: PropTypes.object,
    children: PropTypes.node.isRequired,
};

EndOfPageCTA.defaultProps = {
    cssStyles: null,
};

const ArrowCircleButton = ({className = '', invertedDirection = false, disabled = false, onClick = () => {}}) => {
    const {t} = useTranslation();

    const label = invertedDirection ? t('Previous') : t('Next');
    const classes = [className];

    /* eslint-disable i18next/no-literal-string */
    if (!!disabled) classes.push('arrow-disabled');
    if (!!invertedDirection) classes.push('arrow-prev');
    /* eslint-enable i18next/no-literal-string */

    return (
        <StyledArrowCircleButton
            type="button"
            aria-label={label}
            className={classes.join(' ')}
            onClick={onClick}
            disabled={disabled}
        >
            <ArrowSVG />
        </StyledArrowCircleButton>
    );
};

ArrowCircleButton.propTypes = {
    className: PropTypes.string,
    invertedDirection: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

ArrowCircleButton.defaultProps = {
    className: '',
    invertedDirection: false,
    disabled: false,
    onClick: () => {},
};


/*
 * Exports
 */
export {
    ArrowCircleButton,
    Clickable,
    ContactSalesButton,
    CTA,
    CTAArrow,
    EndOfPageCTA,
    BaseButtonStyles,
    PrimaryButton,
    SecondaryButton,
    LinkAsPrimaryButton,
    LinkAsSecondaryButton,
    EmbeddedVideo,
    EmbeddedPodcast,
    Overlay,
    overlayAnimationTime,
    WatchVideoButton,
    WatchVideoOverlay,
};
